import { useState } from 'react';

const useModal = () => {
	const [isModalopen, setShowModal] = useState(false);
	const [isModalLogin, setIsModalLogin] = useState(false);
	const [modalSaveImage, setModalSaveImage] = useState(false);
	const [modalSheet, setModalSheet] = useState(false);
	const [guideModalState, setGuideModalState] = useState(false);
	const [searchModalState, setSearchModalState] = useState(false);
	const [paymentModalState, setPaymentModalState] = useState(false);
	
	
	const [showImageModal, setShowImageModal] = useState(false);
	const [paymentShowModal, setShowPaymentModal] = useState(false);
	const [showSearchImageModal, setShowSearchImageModal] = useState(false);
	const toggleModal = () => {
		setShowModal((showModal) => !showModal);
	};

	
	const searchtoggleModal = () => {
		setShowSearchImageModal((showModal) => !showModal);
	};
	const imagetoggleModal = () => {
		setShowImageModal((showModal) => !showModal);
	};
	const paymenttoggleModal = () => {
		setShowPaymentModal((showModal) => !showModal);
	};
	const newtoggleModal = () => {
		setModalSaveImage((showModal) => !showModal);
	};
	const loginToggleModal = () => {
		setIsModalLogin((showModal) => !showModal);
	};
	const toggleBottomSheet = () => {
		setModalSheet((showModal) => !showModal);
	};
	const guideModal = () => {
		setGuideModalState((showModal) => !showModal);
	};
	const searchModal = () => {
		setSearchModalState((showModal) => !showModal);
	};
	const paymentModal = () => {
		setPaymentModalState((showModal) => !showModal);
	};
	return {
		paymentModal,
		paymentModalState,
		guideModal,
		guideModalState,
		modalSaveImage,
		isModalopen,
		toggleModal,
		newtoggleModal,
		loginToggleModal,
		isModalLogin,
		toggleBottomSheet,
		modalSheet,
		searchModal,
		searchModalState,
		paymentShowModal,
		paymenttoggleModal,
		showImageModal,
		imagetoggleModal,

		showSearchImageModal,
		searchtoggleModal,
		
	};
};

export default useModal;
