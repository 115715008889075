import styled from 'styled-components';
import Header from './Header';
import { motion } from 'framer-motion';
import { useContext, useEffect, useRef } from 'react';
import { PopContext } from '../context/PopContext';
import withPopContext from '../HOC/withPopContext';
import Modal from './modal';
import NewsLetter from './NewsLetter';
import { RiFeedbackLine } from 'react-icons/ri';

export default withPopContext(function Layout({ children }) {
	const { openNewsLetter, setOpenNewsLetter } = useContext(PopContext);
	return (
		<>
			<Header />
			<FeedbackButton onClick={() => setOpenNewsLetter(!openNewsLetter)}>
				<div>
					<span>Leave a feedback? </span>
					<span>
						<RiFeedbackLine />{' '}
					</span>
				</div>
			</FeedbackButton>
			<main className=''>{children}</main>
			{openNewsLetter && (
				<Modal toggleModal={() => setOpenNewsLetter(!openNewsLetter)}>
					<NewsLetter toggleModal={() => setOpenNewsLetter(!openNewsLetter)} />
				</Modal>
			)}
		</>
	);
});
const FeedbackButton = styled(motion.button)`
	font-size: 14px;
	font-weight: 500;
	color: #fff;
	box-shadow: 0.1px 0.1px 2px rgba(0, 0, 0, 0.5);
	background-color: var(--primary-color);
	padding: 0.5em 1.5em;
	display: flex;
	align-items: center;
	position: fixed;
	bottom: 30px;
	right: 1%;
	z-index: 100;
	border-radius: 50px;
	transition: all 0.2s ease;
	div {
		display: flex;
		align-items: center;
		justify-content: center;

		animation-duration: 1s;
		animation-timing-function: cubic-bezier(0.76, 0.02, 0.23, 0.96);
		animation-delay: 0s;
		animation-iteration-count: infinite;
		animation-direction: normal;
		animation-fill-mode: none;
		animation-play-state: running;
		animation-name: pulse;

		@keyframes pulse {
			from {
				transform: scale(1);
			}
			to {
				transform: scale(1.1);
			}
		}
	}
	span:nth-last-child(1) {
		margin-left: 0.3em;
	}
`;
