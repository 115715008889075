import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Fuse from 'fuse.js';
import { useRouter } from 'next/router';
import { addUserData, getAllNamesInScreens,getAllWebNamesInScreens } from '../../supabase';
// import { Input } from '../uiElements';
// import { ScreensContext } from '../../context/screensContex';
import { BsCommand } from 'react-icons/bs';
import useModal from '../../hooks/useModal';
import Modal from '../modal';
import Link from 'next/link';
import { BrandLogo } from '../uiElements';
import { FaChevronRight } from 'react-icons/fa';
import CloseIcon from '../CloseModalIcon';
import { ModalHeader } from '../AddToBookmark';
import { BiSearch } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import { Tabs } from 'flowbite-react';
import {
	getAllFlows,
	getAllFlowsCount,
	getAllScreensCount,
} from '../../supabase';
import { allcomponents } from '../../utils/components';
import { UserContext } from '../../context/authContext';

interface Options {
	keys: string[];
	isCaseSensitive: boolean;
	includeScore: boolean;
	shouldSort: boolean;
	includeMatches: boolean;
	findAllMatches: boolean;
	minMatchCharLength: number;
	location: number;
	threshold: number;
	distance: number;
	useExtendedSearch: boolean;
	ignoreLocation: boolean;
	ignoreFieldNorm: boolean;
	fieldNormWeight: number;
	title: string;
}

const options: Fuse.IFuseOptions<Options> = {
	keys: ['name','web', 'category', 'component'],
	threshold: 0.3,
	includeMatches: true,
	shouldSort: true,
};

const SearchTabBar = () => {
	const user = useContext(UserContext);
	// const { setFilterItemName } = useContext(ScreensContext);
	const [input, setInput] = useState('');
	const [getSearch, setGetSearch] = useState([]);
	const [getWeb, setGetWeb] = useState([]);
	
	const [getComponents, setGetComponents] = useState(allcomponents);
	const [flows, setFlows] = useState([]);
	const [filter, setFilter] = useState([]);
	const [activetab, setActiveTab] = useState('App');
	const [getbrandscount, setGetBrandsCount] = useState(0);
	const [getflowscount, setGetFlowsCount] = useState(0);
	const [suggestInput, setSuggestInput] = useState({
		feedback: '',
	});
	const [successtext, setSuccessText] = useState(false);

	const router = useRouter();
	/**
	 * @description returns the data to input state
	 * @param {Object}
	 * @param {String}
	 * @returns {None}
	 */
	function handleChange(e) {
		setInput(e.target.value);
	}

	//clear all input
	function clearInput() {
		setInput('');
	}
	//gets all brand data
	useEffect(() => {
		async function search() {
			const flows = await getAllFlows();
			setFlows(flows);
		}
		search();
	}, []);

	//gets All Flows
	useEffect(() => {
		async function search() {
			const screens = await getAllNamesInScreens();
			setGetSearch(screens);
		}
		search();
	}, []);

	useEffect(() => {
		async function search() {
			const screens = await getAllWebNamesInScreens();
			setGetWeb(screens);
		}
		search();
	}, []);

	//runs the fuse search
	useEffect(() => {
		const searchBy =
			activetab === 'Flow'
				? flows
				: activetab === 'App'
				? getSearch
				: activetab === 'Web'
				? getWeb
				: getComponents;

		const fuse = new Fuse(searchBy, options);
		let results: React.SetStateAction<any[]>;
		if (activetab === 'App') {
			results = input ? fuse.search(input || '') : getSearch;
		}
		else if (activetab === 'Web') {
			results = input ? fuse.search(input || '') : getWeb;
		}
		else if (activetab === 'Flow') {
			results = input ? fuse.search(input || '') : flows;
		} else if (activetab === 'UI Element') {
			results = input ? fuse.search(input || '') : getComponents;
		}

		setFilter(results);
	}, [getSearch, input, flows, activetab, getComponents,getWeb]);

	// useEffect(()=>{

	// console.log("leg",filter)
	// console.log("legs",filter[0]?.item.name)
	// },[filter])

	// useEffect(() => {
	// 	//debouncing the search input when typing
	// 	const getData = setTimeout(() => {
	// 		const lowerCaseAll = input.toLowerCase();
	// 		//capitalize the first letter to match the database
	// 		const firstInputCapitalized =
	// 			lowerCaseAll.charAt(0).toUpperCase() + lowerCaseAll.slice(1);
	// 		setFilterItemName(firstInputCapitalized);
	// 	}, 300);
	// 	//Destroy the instance of the useEffect hook using return, followed by clearTimeout, every time it finishes.
	// 	return () => clearTimeout(getData);
	// }, [input, setFilterItemName]);

	//keyboard press functionality

	const { searchModalState, searchModal } = useModal();
	// useEffect(() => {
	// 	let keysPressed = {};
	// 	const keyDownEvent = document.addEventListener('keydown', (event) => {
	// 		keysPressed[event.key] = true;
	// 		console.log(keysPressed)
	// 		if (
	// 			(keysPressed['Meta'] && event.key == 'k') ||
	// 			(keysPressed['Control'] && event.key == 'k')
	// 		) {
	// 			console.log(
	// 				(keysPressed['Meta'] && event.key == 'k') ||
	// 					(keysPressed['Control'] && event.key == 'k')
	// 			);
	// 			searchModal();
	// 		}
	// 	});
	// 	return () => {
	// 		window.removeEventListener('keydown', () => {
	// 			console.log('event removed');
	// 		});
	// 	};
	// }, []);

	//fine-grained control of the click action to close the modal afterwards
	function passRoute(item) {
		if (item?.item?.category || item?.category) {
			router.push(`/flows/${item?.item?.category || item?.category}`);
		} else if (activetab==="App" && item?.item?.name || item?.name) {
			router.push(
				`/screens/${
					item?.item?.name?.toLowerCase() || item?.name?.toLowerCase()
				}/screens/${item?.item?.id || item?.id}`
			);
		}  else if (activetab==="Web") {
			router.push(
				`/web/screens/${
					item?.item?.name?.toLowerCase() || item?.name?.toLowerCase()
				}/screens/${item?.item?.id || item?.id}`
			);
		}else if (item?.item?.component || item?.component) {
			router.push(
				`/components/${
					item?.item?.component?.toLowerCase() || item?.component?.toLowerCase()
				}`
			);
		}

		searchModal();
	}

	//get brands count
	useEffect(() => {
		async function count() {
			const screens = await getAllScreensCount();
			setGetBrandsCount(screens);
		}
		count();
	}, []);

	//get flows count
	useEffect(() => {
		async function count() {
			const screens = await getAllFlowsCount();

			setGetFlowsCount(screens);
		}
		count();
	}, []);

	//display searchbar modal onclick
	function revealSearchModal() {
		searchModal();
	}

	//submit suggestion,change submit text
	async function submitSuggestion(e, type) {
		e.preventDefault();
		const addEmail = { ...suggestInput, email: user?.email ||""};
		await addUserData(type, addEmail);
		
		setSuccessText(true);
	}

	//save suggestions in suggestinput state
	function handleSuggestChange(e) {
		setSuggestInput((prev) => ({ ...prev, feedback: e.target.value }));
	}

	//change from submitted to suggest in 2 secs and clear input field

	if (successtext) {
		setTimeout(() => {
			setSuccessText(false);
			setSuggestInput((prev) => ({ ...prev, feedback: '' }));
		}, 2000);
	}

	return (
		<>





			<FormBackground onClick={revealSearchModal}>
				<div>
					<span>Search Apps,Flows & UI elements</span>
					<span>
						<BiSearch className='search-icon md:text-2xl ' />
					</span>
				</div>
			</FormBackground>

			{searchModalState && (
				<Modal toggleModal={searchModal}>
					<>
						<FormWrapperModal>
							<div className='tabs w-full relative'>
								<div className='tab-headers  w-full '>
									<div
										className=' pl-6  w-3/5  flex items-center
									 gap-6 text-lg font-medium text-gray-400  min-w-fit'
									>
										<span
											className={`tab-header text-md md:text-lg lg:text-1xl pointer center p-3 md:p-4 pl-2 block ${
												activetab === 'App' && 'tab-active'
											}`}
											onClick={() => setActiveTab('App')}
										>
											Mobile{' '}
											{/* <span
												className={`pill ${activetab === 'App' && 'active'}`}
											>
												{getbrandscount}
											</span> */}
										</span>

									
										
										<span
											className={`tab-header text-md md:text-lg lg:text-1xl pointer center p-3 md:p-4 pl-2 block  ${
												activetab === 'Flow' && 'tab-active'
											}`}
											onClick={() => setActiveTab('Flow')}
										>
											Flows{' '}
											{/* <span
												className={`pill ${activetab === 'Flow' && 'active'}`}
											>
												{getflowscount}
											</span> */}
										</span>
										<span
											className={`tab-header text-md md:text-lg lg:text-1xl pointer center p-3 md:p-4 pl-2 block  ${
												activetab === 'UI Element' && 'tab-active'
											}`}
											onClick={() => setActiveTab('UI Element')}
										>
											Elements{' '}
											{/* <span
												className={`pill ${
													activetab === 'UI Element' && 'active'
												}`}
											>
												{getComponents.length}
											</span> */}
										</span>
									</div>
								</div>
								<div className='tab-content  w-full'>
									<FormBackgroundModal>
										<FormInput>
											<label
												className='Search-MagnifierLabel'
												htmlFor='search-input'
												id='search-label'
											>
												<BiSearch />
											</label>
											<input
												type='text'
												value={input}
												onChange={handleChange}
												placeholder={`Search by ${activetab.toLowerCase()}'s name...`}
												id='focus-input'
												autoFocus
											/>
										</FormInput>
										{input && (
											<div onClick={clearInput}>
												<svg width='20' height='20' viewBox='0 0 20 20'>
													<path
														d='M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z'
														stroke='currentColor'
														fill='none'
														fill-rule='evenodd'
														stroke-linecap='round'
														stroke-linejoin='round'
													></path>
												</svg>
											</div>
										)}
									</FormBackgroundModal>
									{JSON.stringify(filter) != JSON.stringify([]) ? (
										<div className='results'>
											{filter.length >= 1 && (
												<h2 className='heading'>
													{activetab}
													{filter.length > 1 && 's'}
												</h2>
											)}

											{filter?.map((item, i) => {
												if (activetab === 'App') {
													return (
														<>
															<div
																key={item?.item?.name + i}
																onClick={() => passRoute(item)}
															>
																<ResultWrapper>
																	<BrandLogo
																		imageUrl={item?.item?.logo || item?.logo}
																		small={true}
																	/>
																	<div className='w-full flex flex-col'>
																		<p>{item?.item?.name || item?.name}</p>
																		<p className='font-normal text-gray-500 description hover:text-white text-sm'>
  {item?.item?.description?.slice(0, 30) || item?.description?.slice(0, 30) || ''}
  {((item?.item?.description?.length || item?.description?.length) >= 30) && '...'}
</p>

																	</div>
																	
																	<FaChevronRight className='app-icon' />
																</ResultWrapper>
															</div>
														</>
													);
												} 	else if (activetab === 'Flow') {
													return (
														<>
															<div
																onClick={() => passRoute(item)}
																key={item.item?.id || item?.id}
															>
																<ResultWrapper key={item.item?.id || item?.id}>
																	{item.item?.category || item?.category}
																	<FaChevronRight className='app-icon' />
																</ResultWrapper>
															</div>
														</>
													);
												} else if (activetab === 'UI Element') {
													return (
														<>
															<div
																onClick={() => passRoute(item)}
																key={item.item?.component || item?.component}
															>
																<ResultWrapper
																	key={item.item?.component || item?.component}
																>
																	{item.item?.component || item?.component}
																	<FaChevronRight className='app-icon' />
																</ResultWrapper>
															</div>
														</>
													);
												}
											})}
										</div>
									) : (
										<div className='results flex flex-col justify-center items-center'>
											<div className='p-28 flex flex-col items-center text-center justify-center'>
												<h2 className='font-semibold text-slate-900 text-2xl'>
													oops!! &#128534; No results found
												</h2>
												<p className='className="mt-2  leading-6 text-slate-600"'>
													Try searching another keyword or suggest an app so we
													can include it
												</p>
												{/* <h2 className='font-medium text-slate-900 text-2xl'>You can suggest the App below so we can include it </h2>	 */}
												<div className=' mt-2 flex flex-col items-center text-center justify-center'>
													<form
														onSubmit={(e) => submitSuggestion(e, 'Feedback')}
													>
														{' '}
														<input
															type='text'
															value={suggestInput.feedback}
															onChange={handleSuggestChange}
															placeholder={`Suggest ${activetab.toLowerCase()}'s name...`}
															id='focus-input'
															className='border border-1 p-3 rounded-lg mb-2'
															required
														/>
														<button
															className='w-full rounded-lg p-3 text-white bg-blue-600'
															type='submit'
														>
															{successtext ? 'Submitted' : 'Suggest'}
														</button>
													</form>
												</div>
											</div>
											<div></div>
										</div>
									)}
								</div>
							</div>
						</FormWrapperModal>
					</>
				</Modal>
			)}
		</>
	);
};

const ResultWrapper = styled.div`
	width: 100%;
	margin-bottom: 12px;
	border-radius: 10px;
	padding: 0.5em;
	background-color: #f0f4fe;
	display: flex;
	font-size: 16px;
	font-weight: 500;
	color: #110f29;
	align-items: center;
	:hover {
		background-color: #0066f5;
		color: white;
		transition: all 0.2s ease-out;
	}
	:hover  .description {
		color: white;
		transition: all 0.2s ease-out;
	}
	.description{
		:hover {
			background-color: #0066f5;
			color: white;
			transition: all 0.2s ease-out;
		}
	}
	.app-icon {
		margin-left: auto;
		margin-right: 0.5em;
	}
`;
const FormInput = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;
const Icon = styled.div`
	display: flex;
	align-items: center;
	font-size: 15px;
	margin: 0 0 0 0.3em;
	color: #777;
	border: 1px solid #ddd;
	border-radius: 0.3em;
	padding: 0.3em;
	span {
		font-family: monospace;
	}
`;

const FormWrapperModal = styled.div`
	overflow: hidden;
	.tab-headers {
		border-bottom: 1px solid #dce2e9;
	}

	.tab-active {
		color: var(--primary-color);
		border-bottom: 2px solid var(--primary-color);
	}
	.tab-header {
		text-align: center;
	}
	.pill {
		font-size: 12px;
		padding: 0.1em 0.6em;
		border-radius: 26px;
		background-color: #eee;
		color: #aaa;
		margin-left: 2px;
		margin-top: 2px;
		display: inline-block;
	}

	.pill.active {
		color: var(--primary-color);
		background-color: #e6f0ff;
	}
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 500px;
	background: white;
	border-radius: 8px;
	width: 90%;
	max-width: 680px;
	overflow-y: hidden;
	.results {
		height: 430px;
		overflow-y: auto;
		width: 100%;
		padding: 0 1.5em 3em;
		.heading {
			font-size: 20px;
			font-weight: 500;
			margin: 0.5em 0;
		}
	}
`;
// const FormWrapper = styled.div`
// 	width: 75%;
// 	max-width: 640px;
// 	/* max-width: 125px; */
// 	/* @media (min-width: 400px) {
// 		max-width: 145px;
// 	}
// 	@media (min-width: 438px) {
// 		max-width: 182px;
// 	}
// 	@media (min-width: 484px) {
// 		max-width: 240px;
// 	}
// 	@media (min-width: 560px) {
// 		max-width: 300px;
// 	}
// 	@media (min-width: 657px) {
// 		max-width: 360px;
// 	}
// 	@media (min-width: 708px) {
// 		max-width: 430px;
// 	}
// 	@media (min-width: 876px) {
// 		max-width: 580px;
// 	}
// 	@media (min-width: 1000px) {
// 		max-width: 640px;
// 	} */
// `;
const FormBackgroundModal = styled.div`
	border-bottom: 1px solid #dce2e9;
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1em 1.5em;
	justify-content: space-between;
	@media (min-width: 760px) {
		padding: 1em 2em;
		width: 100%;
	}

	label {
		font-size: 18px;
		font-weight: 700;
		margin-right: 0.5em;
		color: #999caf;
	}
	input {
		overflow: hidden;
		border: none;
		text-overflow: ellipsis;
		width: 100%;
		font-size: 16px;
		font-weight: 500;
		::placeholder {
			font-size: 16px;
			color: #999caf;
			@media (min-width: 760px) {
				font-size: 16px;
			}
		}
		&:focus {
			box-shadow: none;
			outline: none;
		}
	}
	.close-icon {
		font-size: 20px;
		font-weight: bold;
		margin-left: auto;
		margin-right: 0.5em;
		:hover {
			transform: scale(1.1);
		}
	}
`;
const FormBackground = styled.div`
	cursor: pointer;
	background: #fff;
	color: #777;
	display: flex;
	align-items: center;
	border-radius: 1.5em;
	padding: 0.7em 1em;
	width: 75%;
	max-width: 650px;

	@media (min-width: 760px) {
		padding: 0.7em 2em;
	}

	div {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	span {
		white-space: nowrap;
		display: flex;
		align-items: center;
		font-weight: 500;

		:first-child {
			font-size: 12px;
			flex: 3;

			@media (min-width: 760px) {
				font-size: 16px;
			}
		}
	}
`;

export default SearchTabBar;
